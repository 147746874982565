import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faIndustry, faPlusCircle, faSchool, faSearch, faAddressBook, faTrash, faPen, faUser, faCheck, faIdCard, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faEllipsisV, faDownload, faFilePdf, faQuestion, faFile, faCalendar, faCalendarAlt, faShare, faHandshake, faRefresh } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { timeSince, convert2Base64, dueWithBadge, downloadFile } from '../../../tools/tools';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { params } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';
import { colors } from '../../../tools/constants';


export default function BusinessDetails(props) {

    const PAGE_TITLE = "Cliente";
    const PAGE_ICON = faIndustry;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [color, setColor] = React.useState("");


    const [name, setName] = React.useState("");

    const [bills, setBills] = React.useState([]);
    const [taxConditions, setTaxConditions] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);

    const [error, setError] = React.useState(<div></div>);
    const [payments, setPayments] = React.useState([]);

    const [balance, setBalance] = React.useState(<div></div>);

    const [services, setServices] = React.useState([]);
    const [contractedServices, setContractedServices] = React.useState([]);


    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [customerBalance, setCustomerBalance] = React.useState([]);
    const [customerBalanceIndicators, setCustomerBalanceIndicators] = React.useState([]);

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showDebtNotificationModal, setShowDebtNotificationModal] = React.useState(false);
    const handleCloseDebtNotificationModal = () => { setShowDebtNotificationModal(false); };
    const handleShowDebtNotificationModal = () => {
        setShowDebtNotificationModal(true);
    };

    const [showPriceIncrementModal, setShowPriceIncrementModal] = React.useState(false);
    const handleClosePriceIncrementModal = () => { setShowPriceIncrementModal(false); };
    const handleShowPriceIncrementModal = () => {
        setShowPriceIncrementModal(true);
    };

    const [showSetOperationPaidModal, setShowSetOperationPaidModal] = React.useState(false);
    const [selectedOperationId, setSelectedOperationId] = React.useState(0);
    const handleCloseSetOperationPaidModal = () => { setShowSetOperationPaidModal(false); };
    const handleShowSetOperationPaidModal = (id) => {
        setSelectedOperationId(id)
        setShowSetOperationPaidModal(true);
    };

    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const [newOperationStartDate, setNewOperationStartDate] = React.useState(new Date());
    const [newOperationEndDate, setNewOperationEndDate] = React.useState(new Date());
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };

    const [showDeleteBusinessModal, setShowDeleteBusinessModal] = React.useState(false);
    const handleCloseDeleteBusinessModal = () => { setShowDeleteBusinessModal(false); };
    const handleShowDeleteBusinessModal = () => {
        setShowDeleteBusinessModal(true);
    }
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);


    const [showInflationCalculator, setShowInflationCalculator] = React.useState(false);
    const handleCloseInflationCalculator = () => { setShowInflationCalculator(false); };
    const handleShowInflationCalculator = () => {
        setShowInflationCalculator(true);
    }

    const [showNewContractModal, setShowNewContractModal] = React.useState(false);
    const handleCloseNewContractModal = () => { setShowNewContractModal(false); };
    const handleShowNewContractModal = () => {
        getServices();
        setShowNewContractModal(true);
    };
    const [newContractStartDate, setNewContractStartDate] = React.useState(new Date());
    const [newContractEndDate, setNewContractEndDate] = React.useState(new Date());

    const [selectedService, setSelectedService] = React.useState({});

    const [showEditContractModal, setShowEditContractModal] = React.useState(false);
    const handleCloseEditContractModal = () => { setShowEditContractModal(false); };
    const handleShowEditContractModal = (c) => {
        setSelectedContract(c)
        setEditContractStartDate(new Date(moment(c.startDate)))
        setEditContractEndDate(new Date(moment(c.endDate)))
        setShowEditContractModal(true);
    };
    const [editContractStartDate, setEditContractStartDate] = React.useState(new Date());
    const [editContractEndDate, setEditContractEndDate] = React.useState(new Date());

    const handleNewContractService = (event) => {
        if (event.target.value != "0") {
            getServiceById(event.target.value);
        }

    };


    const handleNewContractStartDate = (event) => {
        setNewContractStartDate(event);
    };

    const handleNewContractEndDate = (event) => {
        setNewContractEndDate(event);
    };

    const handleEditContractStartDate = (event) => {
        setEditContractStartDate(event);
    };

    const handleEditContractEndDate = (event) => {
        setEditContractEndDate(event);
    };

    const [selectedContract, setSelectedContract] = React.useState({});
    const [newContractRenewalStartDate, setNewContractRenewalStartDate] = React.useState(new Date());
    const [newContractRenewalEndDate, setNewContractRenewalEndDate] = React.useState(new Date());
    const [showNewContractRenewalModal, setShowNewContractRenewalModal] = React.useState(false);
    const handleCloseNewContractRenewalModal = () => { setShowNewContractRenewalModal(false); };
    const handleShowNewContractRenewalModal = (id, amount) => {
        setSelectedContract({
            id: id,
            amount: amount
        })
        setShowNewContractRenewalModal(true);
    };

    const handleNewContractRenewalStartDate = (event) => {
        setNewContractRenewalStartDate(event);
    };

    const handleNewContractRenewalEndDate = (event) => {
        setNewContractRenewalEndDate(event);
    };

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewOperationStartDate = (event) => {
        setNewOperationStartDate(event);
    };

    const handleNewOperationEndDate = (event) => {
        setNewOperationEndDate(event);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [selectedContact, setSelectedContact] = React.useState({ id: 0, name: "", data: [] });
    const [showEditContactModal, setShowEditContactModal] = React.useState(false);
    const handleCloseEditContactModal = () => { setShowEditContactModal(false); };
    const handleShowEditContactModal = (id, name, data) => {
        setSelectedContact({ id: id, name: name, data: data })
        setShowEditContactModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `secdevs - ${PAGE_TITLE}`;


        setupTable();
        getData();
        getContacts();
        //getCurrentAccount();
        //getCurrentAccountBalance();

    }, []);

    const getData = async function () {
        await getProvinces();
        await getTaxConditions();
        getCustomer();
        getContractedServices();
        getBills();
        getPayments();
        getPaymentMethods();
    }

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPaymentMethods = async function () {
        const req = await get('/administration/cashFlow/methods', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setPaymentMethods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getServices = async function () {
        const req = await get('/services', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setServices(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getContractedServices = async function () {
        const req = await get(`/services/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processContractedServices(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processContractedServices = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowNewContractRenewalModal(e.id, e.amount)}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 10, marginLeft: 2 }} />Renovar</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowEditContractModal(e)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Editar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )


            result.push(e)
        }

        setContractedServices(result)
    }


    const getServiceById = async function (id) {
        console.log(id)
        const req = await get(`/services/byId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSelectedService(res)
            document.getElementById('textNewContractAmount').value = res.amount
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTaxConditions = async function () {
        const req = await get('/customers/taxConditions', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setTaxConditions(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getContacts = async function () {
        const req = await get(`/customers/contacts/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getBills = async function () {
        const req = await get(`/administration/bill/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log("---BILLS---")
            console.log(res)
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {


            if (d.date) {
                d.day = (moment(d.date).format('DD/MM/YYYY'))
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.fileId) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }

    const processContacts = function (data) {
        console.log(data)
        let result = [];
        let aux;
        let icon;
        let cdata = [];

        for (let c of data) {
            aux = null;
            cdata = []

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "WHAT":
                        icon = <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }
                cdata.push({
                    icon: icon,
                    data: c.ContactData[i].data,
                    id: c.ContactData[i].id
                })
            }
            c.data = cdata;
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 10, marginLeft: 2 }} />Agregar datos</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowEditContactModal(c.id, c.name, c.data)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            result.push(c);
        }
        setBusinessContacts(result);
    }

    const getCustomer = async function () {
        const req = await get(`/customers/byId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomer(res)
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    const processCustomer = async function (data) {
        console.log(data)
        setName(data.name);

        document.getElementById('textName').value = data.name;
        document.getElementById('textLegalName').value = data.legalName;
        document.getElementById('selectLegalIdType').value = data.legalIdType;
        document.getElementById('textLegalId').value = data.legalId;
        document.getElementById('selectTaxCondition').value = data.taxConditionId;
        document.getElementById('textAddress').value = data.address;
        document.getElementById('textCity').value = data.city;
        document.getElementById('selectProvince').value = data.province
        document.getElementById('textComment').value = data.comment
        document.getElementById('textPostalCode').value = data.postalCode

    }



    const editCustomer = async () => {
        let body = {
            name: document.getElementById('textName').value,
            legalName: document.getElementById('textLegalName').value,
            legalIdType: parseInt(document.getElementById('selectLegalIdType').value),
            legalId: document.getElementById('textLegalId').value,
            taxConditionId: parseInt(document.getElementById('selectTaxCondition').value),
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: document.getElementById('selectProvince').value,
            comment: document.getElementById('textComment').value
        }

        console.log(body)

        const req = await put(`/customers/byId/${getParamId()}`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        setCustomerBalanceCol(['Fecha', 'Carátula', 'A transferir', 'Transferido', ''])
    }

    const changeCurrentContactType = function (type) {
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function () {
        setShowNewContactData(false);

        let param;

        switch (currentContactType) {
            case "Teléfono":
                param = "PHON";
                break;
            case "WhatsApp":
                param = "WHAT";
                break;
            case "Correo electrónico":
                param = "EMAI";
                break;
            case "Dirección":
                param = "ADDR";
                break;
            case "Cargo":
                param = "POSI";
                break;
            case "DNI":
                param = "DNIN";
                break;
            case "Otros":
                param = "OTHE";
                break;
            default:
                break;
        }

        const contact = {
            contactId: currentContact,
            param: param,
            data: newContactData
        };

        const req = await post(`/customers/contacts/data`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const deleteContact = async function () {
        handleCloseSureDelete();

        const body = {
            contactId: currentContact,
        }
        const req = await del(`/customers/contacts`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteCustomer = async function () {
        const body = {
            customerId: getParamId(),
        }
        const req = await del(`/customers/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/clientes`;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const deleteContactData = async function (id) {
        const body = {
            contactDataId: id,
        }
        console.log(body)
        const req = await del(`/customers/contacts/data`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseEditContactModal();
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalanceById = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name
            } else {
                e.name = '---'
            }

            result.push(e)
        }

        setCustomerBalance(result)
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountIndicators = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCustomerBalanceIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createContact = async function () {
        setShowNewContact(false);

        const body = {
            customerId: getParamId(),
            name: newContactName,
        };

        const req = await post(`/customers/contacts/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPayments = async function () {
        const req = await get(`/administration/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processPayments(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const processPayments = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.File ? faFile : null;
            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                    {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    {d.cashFlowId == null ? <Dropdown.Item onClick={() => handleShowSetOperationPaidModal(d.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8, marginLeft: 2 }} />Marcar como cobrado</Dropdown.Item> : <div></div>}
                    <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);
            /*if (d.PaymentCategory != null) {
                d.category = <Badge bg='' className='content-table status' style={{ backgroundColor: `${d.PaymentCategory.color}` }}>{d.PaymentCategory.name}</Badge>;
            } else {
                d.category = <div></div>
            }

            if (d.PaymentMethod != null) {
                d.method = <Badge bg='' className='content-table status' style={{ backgroundColor: `${d.PaymentMethod.color}` }}>{d.PaymentMethod.name}</Badge>;
            } else {
                d.method = <div></div>
            }*/


            result.push(d)
        }

        setPayments(result)
    }

    const createOperation = async function () {
        let body = {
            date: newOperationDate,
            description: document.getElementById('newOperationDescription').value,
            amount: parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.')),
            customerId: getParamId(),
            startDate: newOperationStartDate,
            endDate: newOperationEndDate
        }

        const req = await post(`/administration/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createCashFlow = async function () {
        let body = {
            paymentId: selectedOperationId,
            date: newOperationDate,
            paymentMethodId: document.getElementById("selectNewOperationPaymentMethod").value
        }

        const req = await post(`/administration/cashFlow/paymentaspaid`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const calculateInflation = async function (status) {
        const body = {
            fromMonth: document.getElementById('selectFromMonth').value,
            fromYear: document.getElementById('selectFromYear').value,
            toMonth: document.getElementById('selectToMonth').value,
            toYear: document.getElementById('selectToYear').value,
            amount: document.getElementById('textInitialAmount').value
        }

        const req = await post('/administration/inflation', getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            document.getElementById('textResultAmount').value = res.result;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async function () {
        const body = {
            id: deleteOperationId,
        }
        const req = await del(`/administration/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createContract = async function () {
        const body = {
            customerId: getParamId(),
            serviceId: document.getElementById('selectNewContractService').value,
            startDate: newContractStartDate,
            endDate: newContractEndDate,
            amount: document.getElementById('textNewContractAmount').value,
            paymentInAdvance: document.getElementById('checkNewContractPaymentInAdvance').checked,
            automaticRenewal: document.getElementById('checkNewContractAutoRenewal').checked,
            billAtRenewal: document.getElementById('checkNewContractBillAtRenewal').checked,
        }

        const req = await post(`/services/contract`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewContractModal()
            getData();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const editContract = async function () {
        const body = {
            contractId: selectedContract.id,
            startDate: editContractStartDate,
            endDate: editContractEndDate,
            amount: document.getElementById('textEditContractAmount').value,
            paymentInAdvance: document.getElementById('checkEditContractPaymentInAdvance').checked,
            automaticRenewal: document.getElementById('checkEditContractAutoRenewal').checked,
            billAtRenewal: document.getElementById('checkEditContractBillAtRenewal').checked,
        }

        const req = await put(`/services/contract`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseEditContractModal()
            getData();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const renewContract = async function () {
        const body = {
            contractId: selectedContract.id,
            startDate: newContractRenewalStartDate,
            endDate: newContractRenewalEndDate,
            amount: document.getElementById('textNewContractRenewalAmount').value,
        }

        const req = await put(`/services/contract/renew`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewContractRenewalModal()
            getData();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createDebtNotification = async function () {
        const body = {
            customerId: getParamId(),
            message: document.getElementById('debtNotificationMessage').value
        }

        const req = await post(`/notifications/debt`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseDebtNotificationModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createIncrementPriceNotification = async function () {
        const body = {
            customerId: getParamId(),
            message: document.getElementById('priceIncrementNotificationMessage').value
        }

        const req = await post(`/notifications/priceIncrement`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleClosePriceIncrementModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 20, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{name}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar</Button>
                                <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} /></Button>
                                <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} /></Button>
                            </Col>
                        </Row>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Nombre
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Nombre</Form.Text>
                                                <Form.Control type="text" id="textName" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Razón social
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Razón social</Form.Text>
                                                <Form.Control type="text" id="textLegalName" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Tipo de documento
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Tipo de documento</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectLegalIdType">
                                                    <option value="0">Seleccionar...</option>
                                                    <option value={80}>CUIT</option>
                                                    <option value={86}>CUIL</option>
                                                    <option value={96}>DNI</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            CUIT / CUIL / DNI
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>CUIT / CUIL / DNI</Form.Text>
                                                <Form.Control type="text" id="textLegalId" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Condición IVA
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Condición IVA</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectTaxCondition">
                                                    <option value="0">Seleccionar...</option>
                                                    {taxConditions.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Dirección
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Dirección </Form.Text>
                                                <Form.Control type="text" id="textAddress" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Código postal
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Código postal</Form.Text>
                                                <Form.Control type="text" id="textPostalCode" readOnly placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Localidad
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Localidad</Form.Text>
                                                <Form.Control type="text" id="textCity" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Provincia
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Provincia</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectProvince">
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={12} md={6}>
                                    <Row className='mt-2'>
                                        <Col md={3} className='d-none d-sm-block align-center-vertically-v2'>
                                            Comentarios
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Comentarios</Form.Text>
                                                <Form.Control as="textarea" id="textComment" readOnly={!isEditing} rows={15} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {isEditing ?
                                <div className='mt-5' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={editCustomer}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }
                        </Container>
                    </Card>
                </Row>

            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Contactos
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo contacto</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                Contactos
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>
                            {businessContacts.length > 0 ?
                                <div>
                                    {businessContacts.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2' xs={3}>
                                                {r.name}
                                            </Col>
                                            <Col className='' xs={7} md={8}>
                                                {r.data.map((d, i) => (
                                                    <Badge bg='secondary' className='content-table status me-2'><span className='me-1'>{d.icon}</span> {d.data}</Badge>
                                                ))}

                                            </Col>
                                            <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                {r.action}
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                                :
                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                                </Row>
                            }

                        </Container>

                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Servicios contratados
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="warning" onClick={handleShowPriceIncrementModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Notificación de precios</Button>
                                <Button className='ms-2' variant="success" onClick={handleShowNewContractModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo servicio</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                Servicios contratados
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewContractModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>
                            <div className='d-none d-sm-block'>
                                <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                    <Col sm={5} className='align-center-vertically-v2'>
                                        <div>
                                            Servicio
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Inico
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Vencimiento
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Estado
                                        </div>
                                    </Col>



                                    <Col sm={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {contractedServices.length > 0 ?
                                <div>
                                    {contractedServices.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2' xs={5}>
                                                <p className='m-0' style={{ fontSize: 11 }}><Badge bg={r.automaticRenewal ? "success" : "danger"} className=''>Renovación automática</Badge><Badge bg={r.paymentInAdvance ? "success" : "danger"} className='ms-1'>{r.paymentInAdvance ? "Pago adelantado" : "Pago vencido"}</Badge><Badge bg={r.billAtRenewal ? "success" : "danger"} className='ms-1'>{r.billAtRenewal ? "Facturación auto." : "Sin facturación auto."}</Badge></p>
                                                <p className='m-0'>{r.Service.name}</p>
                                            </Col>

                                            <Col className='align-center-vertically-v2' xs={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {moment(r.startDate).format("DD/MM/YYYY")}
                                                </div>
                                            </Col>

                                            <Col className='align-center-vertically-v2' xs={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {moment(r.endDate).format("DD/MM/YYYY")}
                                                </div>
                                            </Col>

                                            <Col className='align-center-vertically-v2' xs={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {moment().isBefore(moment(r.endDate).add(1, 'day')) ? <Badge bg='success' className='content-table status'>Activo</Badge> : <Badge bg='danger' className='content-table status'>Vencido</Badge>}
                                                </div>
                                            </Col>



                                            <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                {r.action}
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                                :
                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faHandshake}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay servicios contratados</p>
                                </Row>
                            }

                        </Container>

                    </Card>
                </Row>



                {getUserRole() == "Administrador" ?

                    <Row>
                        <Card className='cards content mb-5'>


                            <Row className='content-options content'>
                                <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                    <span>Cuenta corriente</span><span className='ms-2'>{balance}</span>
                                </Col>
                                <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                    <Button variant="warning" onClick={handleShowDebtNotificationModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Notificación de deuda</Button>
                                    <Button variant="success" onClick={handleShowNewOperationModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>
                                </Col>

                                <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                    <span>Cuenta corriente</span><span className='ms-2'>{balance}</span>
                                </Col>
                                <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    <Button variant="danger" onClick={handleShowNewOperationModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} /></Button>
                                </Col>
                            </Row>

                            <Container className='mt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={7} className='align-center-vertically-v2'>
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Monto
                                            </div>
                                        </Col>


                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {payments.length > 0 ?
                                    <div>
                                        {payments.map((r, i) => (
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col className='align-center-vertically-v2' xs={5}>
                                                    {r.startDate ? <p className='m-0' style={{ fontSize: 12 }}>{moment(r.startDate).format("DD/MM/YYYY")} al {moment(r.endDate).format("DD/MM/YYYY")}</p> : <div></div>}
                                                    <p className='m-0'>{r.description}</p>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'><Badge bg={r.cashFlowId ? "success" : "danger"}>{r.cashFlowId ? "Pagado" : "Pago pendiente"}</Badge><Badge bg={r.billId ? "success" : "danger"} className='ms-1'>{r.billId ? "Facturado" : "Factura pendiente"}</Badge></p>
                                                    </div>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {moment(r.date).format("DD/MM/YYYY")}
                                                    </div>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        ${pn(r.amount)}
                                                    </div>
                                                </Col>





                                                <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                    {r.action}
                                                </Col>

                                            </Row>
                                        ))}
                                    </div>
                                    :
                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay operaciones</p>
                                    </Row>
                                }
                            </Container>


                        </Card>
                    </Row>

                    :

                    <div></div>

                }


                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Facturas emitidas (últimas 10)
                                </td>
                                <td className='content-options rows right'>
                                </td>
                            </tr>
                        </table>

                        <Container className='pt-3 mb-3'>
                            {bills.map((b, i) => (
                                <Row className={`p-2 mb-2`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div>
                                            <p className='m-0'><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                        </div>

                                    </Col>

                                    <Col xs={4} className='align-center-vertically-v2' >
                                        <div>
                                            <p className='m-0' style={{ fontSize: 12 }}>{moment(b.serviceStartDate).format("DD/MM/YYYY")} al {moment(b.serviceEndDate).format("DD/MM/YYYY")}</p>
                                            <p className='m-0'>{b.description}</p>
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2'>
                                        <div>
                                            <p className='m-0'>{b.day}</p>
                                        </div>
                                    </Col>

                                    <Col xs={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'right' }}>
                                            {b.action}
                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </Container>



                    </Card>
                </Row>



                <Modal show={showDeleteBusinessModal} onHide={handleCloseDeleteBusinessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar al cliente?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteBusinessModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomer}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Nuevo contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Juan Pérez"
                                        autoFocus
                                        onChange={(event) => handleNewContactName(event)}
                                    />
                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContact}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={createContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Añadir datos de contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Col xs={5}>
                                            <Dropdown className='w-100'>
                                                <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                    <span id='currentContactType'>Seleccionar...</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Teléfono")}>Teléfono</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("WhatsApp")}>WhatsApp</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("DNI")}>DNI</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>

                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                autoFocus
                                                onChange={(event) => handleNewContactData(event)}
                                            />
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContactData}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={createContactData}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                    <Modal.Header closeButtonSureDelete>
                        <Modal.Title>Eliminar contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Está seguro de eliminar al contacto {currentContactName}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSureDelete}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={deleteContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomerBalance}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showInflationCalculator} centered onHide={handleCloseInflationCalculator}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Calculadora de inflación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Desde mes</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectFromMonth">
                                            <option value="0">Seleccionar...</option>
                                            <option value={1}>Enero</option>
                                            <option value={2}>Febrero</option>
                                            <option value={3}>Marzo</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Mayo</option>
                                            <option value={6}>Junio</option>
                                            <option value={7}>Julio</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Septiembre</option>
                                            <option value={10}>Octubre</option>
                                            <option value={11}>Noviembre</option>
                                            <option value={12}>Diciembre</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Desde año</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectFromYear">
                                            <option value="0">Seleccionar...</option>
                                            <option value={2021}>2021</option>
                                            <option value={2022}>2022</option>
                                            <option value={2023}>2023</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Hasta mes</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectToMonth">
                                            <option value="0">Seleccionar...</option>
                                            <option value={1}>Enero</option>
                                            <option value={2}>Febrero</option>
                                            <option value={3}>Marzo</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Mayo</option>
                                            <option value={6}>Junio</option>
                                            <option value={7}>Julio</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Septiembre</option>
                                            <option value={10}>Octubre</option>
                                            <option value={11}>Noviembre</option>
                                            <option value={12}>Diciembre</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Hasta año</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectToYear">
                                            <option value="0">Seleccionar...</option>
                                            <option value={2021}>2021</option>
                                            <option value={2022}>2022</option>
                                            <option value={2023}>2023</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className='mt-2'>
                                <Form.Text>Monto</Form.Text>
                                <Form.Control type="text" id="textInitialAmount" placeholder="" />
                            </Form.Group>

                            <Form.Group className='mt-2'>
                                <Form.Text>Resultado</Form.Text>
                                <Form.Control type="text" id="textResultAmount" placeholder="" />
                            </Form.Group>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseInflationCalculator}>
                            Cancelar
                        </Button>

                        <Button variant="primary" onClick={calculateInflation}>
                            Calcular
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva operación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group className=''>
                            <Form.Text>Fecha</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Inicio servicio</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationStartDate} onChange={(d) => handleNewOperationStartDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Fin servicio</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationEndDate} onChange={(d) => handleNewOperationEndDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Descripción</Form.Text>
                            <Form.Control type="text" id="newOperationDescription" placeholder="" />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Monto</Form.Text>
                            <Form.Control type="text" id="newOperationAmount" placeholder="" />
                        </Form.Group>

                        {error}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                            Cerrar
                        </Button>

                        <Button variant="danger" onClick={createOperation}>Crear</Button>


                    </Modal.Footer>
                </Modal>


            </Container>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDebtNotificationModal} onHide={handleCloseDebtNotificationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Notificación de deuda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Mensaje</Form.Text>
                        <Form.Control as="textarea" rows={5} id="debtNotificationMessage" placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDebtNotificationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={createDebtNotification}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSetOperationPaidModal} onHide={handleCloseSetOperationPaidModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Marcar servicio como abonado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Método de pago</Form.Text>
                        <Form.Select className='' id="selectNewOperationPaymentMethod">
                            <option value="0">Seleccionar...</option>
                            {paymentMethods.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSetOperationPaidModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={createCashFlow}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewContractModal} onHide={handleCloseNewContractModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Servicio</Form.Text>
                        <Form.Select className='' id="selectNewContractService" onChange={(event) => handleNewContractService(event)}>
                            <option value="0">Seleccionar...</option>
                            {services.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.ServiceType ? p.ServiceType.name : ""} - {p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de inicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newContractStartDate} onChange={(d) => handleNewContractStartDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de vencimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newContractEndDate} onChange={(d) => handleNewContractEndDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="textNewContractAmount" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkNewContractPaymentInAdvance'
                            defaultChecked
                            label='Pago por adelantado'
                        />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkNewContractAutoRenewal'
                            defaultChecked
                            label='Renovación automática'
                        />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkNewContractBillAtRenewal'
                            defaultChecked
                            label='Facturar antes de cobrar'
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewContractModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createContract}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditContractModal} onHide={handleCloseEditContractModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de inicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={editContractStartDate} onChange={(d) => handleEditContractStartDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de vencimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={editContractEndDate} onChange={(d) => handleEditContractEndDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="textEditContractAmount" placeholder="" defaultValue={selectedContract.amount} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkEditContractPaymentInAdvance'
                            defaultChecked={selectedContract.paymentInAdvance}
                            label='Pago por adelantado'
                        />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkEditContractAutoRenewal'
                            defaultChecked={selectedContract.automaticRenewal}
                            label='Renovación automática'
                        />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Check
                            type='checkbox'
                            id='checkEditContractBillAtRenewal'
                            defaultChecked={selectedContract.billAtRenewal}
                            label='Facturar antes de cobrar'
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditContractModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={editContract}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditContactModal} onHide={handleCloseEditContactModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup className='mt-2'>
                        {selectedContact.data.map((d, i) => (
                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col className='align-center-vertically-v2' xs={10} md={10}>
                                    <span className='me-1'>{d.icon}</span> {d.data}

                                </Col>
                                <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => deleteContactData(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>

                            </Row>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditContactModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewContractRenewalModal} onHide={handleCloseNewContractRenewalModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Renovar servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nueva fecha de inicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newContractRenewalStartDate} onChange={(d) => handleNewContractRenewalStartDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Nueva fecha de vencimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newContractRenewalEndDate} onChange={(d) => handleNewContractRenewalEndDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="textNewContractRenewalAmount" placeholder="" defaultValue={selectedContract.amount} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewContractRenewalModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={renewContract}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPriceIncrementModal} onHide={handleClosePriceIncrementModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Aviso de incremento de precios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Mensaje</Form.Text>
                        <Form.Control as="textarea" rows={5} id="priceIncrementNotificationMessage" placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePriceIncrementModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={createIncrementPriceNotification}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}