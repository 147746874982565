import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge, ButtonGroup, ToggleButton, ListGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes, faFile, faHistory } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserId, getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince, convert2Base64, downloadFile } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params, colors } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function CourtFiles() {

    const PAGE_TITLE = "Administración";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tableRows2, setTableRows2] = React.useState([]);

    const [billLastCheck, setBillLastCheck] = React.useState(new Date());

    const [error, setError] = React.useState(<div></div>);
    const [bills, setBills] = React.useState([]);
    const [cashFlowIndicators, setCashFlowIndicators] = React.useState([]);
    const [paymentCategories, setPaymentCategories] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [employeesCurrentAccount, setEmployeesCurrentAccount] = React.useState([]);
    const [pendingCurrentAccount, setPendingCurrentAccount] = React.useState([]);
    const [cashFlow, setCashFlow] = React.useState([]);
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };
    const [newOperationIsCredit, setNewOperationIsCredit] = React.useState(false);

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [newBillCustomerText, setNewBillCustomerText] = React.useState("");
    const [newBillProducts, setNewBillProducts] = React.useState([]);
    const [newBillAmount, setNewBillAmount] = React.useState("");
    const [newBillDescription, setNewBillDescription] = React.useState("");
    const [newBillStartDate, setNewBillStartDate] = React.useState(new Date());
    const [newBillEndDate, setNewBillEndDate] = React.useState(new Date());
    const [showNewBillModal, setShowNewBillModal] = React.useState(false);
    const handleCloseNewBillModal = () => { setShowNewBillModal(false); };
    const handleShowNewBillModal = () => {
        setNewBillStartDate(new Date())
        setNewBillEndDate(new Date())
        setSelectedCustomer(null)
        setError()
        setShowNewBillModal(true);
    };

    const [showNewBillConfirmationModal, setShowNewBillConfirmationModal] = React.useState(false);
    const handleCloseNewBillConfirmationModal = () => { setShowNewBillConfirmationModal(false); };
    const handleShowNewBillConfirmationModal = () => {
        setShowNewBillConfirmationModal(true);
    };

    const [cashFlowHistory, setCashFlowHistory] = React.useState([]);
    const [showCashFlowHistoryModal, setShowCashFlowHistoryModal] = React.useState(false);
    const handleCloseCashFlowHistoryModal = () => { setShowCashFlowHistoryModal(false); };
    const handleShowCashFlowHistoryModal = () => {
        getCurrentAccountHistory()
        setShowCashFlowHistoryModal(true);
    };

    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [searchedCustomers, setSearchedCustomers] = React.useState([]);
    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };

    const [cashFlowGraphicData, setCashFlowGraphicData] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);

    const [showEditSalaryEmployeeModal, setShowEditSalaryEmployeeModal] = React.useState(false);
    const [editSalaryEmployeeId, setEditSalaryEmployeeId] = React.useState(0);
    const handleCloseEditSalaryEmployeeModal = () => { setShowEditSalaryEmployeeModal(false); };
    const handleShowEditSalaryEmployeeModal = (id) => {
        setEditSalaryEmployeeId(id)
        setShowEditSalaryEmployeeModal(true);
    };

    const [showNewEmployeeModal, setShowNewEmployeeModal] = React.useState(false);
    const handleCloseNewEmployeeModal = () => { setShowNewEmployeeModal(false); };
    const handleShowNewEmployeeModal = () => {
        setShowNewEmployeeModal(true);
    };

    const [employeeAdmissionDate, setEmployeeAdmissionDate] = React.useState(new Date());
    const [employeeBirthdayDate, setEmployeeBirthdayDate] = React.useState(new Date());

    const handleNewEmployeeAdmissionDate = (event) => {
        setEmployeeAdmissionDate(event);
    };

    const handleNewEmployeeBirthdayDate = (event) => {
        setEmployeeBirthdayDate(event);
    };

    const [showPaySalaryEmployeeModal, setShowPaySalaryEmployeeModal] = React.useState(false);
    const [paySalaryEmployeeId, setPaySalaryEmployeeId] = React.useState(0);
    const handleClosePaySalaryEmployeeModal = () => { setShowPaySalaryEmployeeModal(false); };
    const handleShowPaySalaryEmployeeModal = (id) => {
        setPaySalaryEmployeeId(id)
        setShowPaySalaryEmployeeModal(true);
    };

    const handleCustomerSelected = (index) => {
        for (let c of customers) {
            if (c.id == index) {
                setSelectedCustomer(c);
                document.getElementById('textCustomer').value = c.name
                setSearchedCustomers([]);
                handleCloseSearchCustomerModal();
            }
        }
    };

    const handleSearchCustomers = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setSearchedCustomers(res)
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setSearchedCustomers([])
        }
    };


    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleNewOperationIsCredit = (event) => {
        setNewOperationIsCredit(event == "Egreso");
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewBillStartDate = (event) => {
        setNewBillStartDate(event);
    };

    const handleNewBillEndDate = (event) => {
        setNewBillEndDate(event);
    };

    const handleNewOperationCategory = (event) => {
        setNewOperationCategory(event.target.value);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale('es', es);

        document.title = `secdevs - ${PAGE_TITLE}`;

        getCashFlowIndicators();
        getCashFlowMethods();
        getCashFlowCategories();
        getEmployees();
        getCustomers();
        getCashFlow();
        //getEmployeesLastMovement();
        //getPendingCurrentAccount();
        getBills();
        //getLastBillCheck();
        getCashFlowGraphic();
    }, []);



    const getCashFlowIndicators = async function () {
        const req = await get(`/administration/cashFlow/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCashFlowIndicators(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCashFlowMethods = async function () {
        const req = await get(`/administration/cashFlow/methods`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPaymentMethods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCashFlowCategories = async function () {
        const req = await get(`/administration/cashFlow/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPaymentCategories(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCashFlowGraphic = async function () {
        const req = await get(`/administration/cashFlow/graphic`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCashFlowGraphicData(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEmployees = async function () {
        const req = await get(`/employees/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processEmployees(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processEmployees = (data) => {
        let result = [];

        for (let d of data) {
            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleShowEditSalaryEmployeeModal(d.id)}><FontAwesomeIcon icon={faCoins} style={{ marginRight: 10, marginLeft: 2 }} />Modificar sueldo</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowPaySalaryEmployeeModal(d.id)}><FontAwesomeIcon icon={faCoins} style={{ marginRight: 10, marginLeft: 2 }} />Pagar sueldo</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);

            result.push(d)
        }

        setEmployees(result)
    }

    const editEmployeeSalary = async function () {
        const data = {
            employeeId: editSalaryEmployeeId,
            amount: document.getElementById('textEmployeeSalary').value
        }
        const req = await put(`/employees/salaries/amount`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getEmployees();
            handleCloseEditSalaryEmployeeModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createEmployee = async function () {
        const data = {
            name: document.getElementById('textEmployeeName').value,
            surname: document.getElementById('textEmployeeSurname').value,
            taxId: document.getElementById('textEmployeeTaxId').value,
            admissionDate: employeeAdmissionDate,
            birthdayDate: employeeBirthdayDate,
            salary: document.getElementById('textEmployeeSalary').value
        }
        const req = await post(`/employees/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getEmployees();
            handleCloseNewEmployeeModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const payEmployeeSalary = async function () {
        const data = {
            paymentMethodId: document.getElementById('selectEmployeePaymentMethodId').value,
            employeeId: paySalaryEmployeeId,
        }
        const req = await post(`/employees/salaries/pay`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getEmployees();
            handleCloseNewEmployeeModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomers = async function () {
        const req = await get(`/customers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCustomers(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLastBillCheck = async function () {
        const req = await get(`/config/byParam/AFIP_LASTCHECK`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setBillLastCheck(new Date(res.value));
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCashFlow = async function () {
        const req = await get(`/administration/cashFlow/last10`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processCashFlow(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountHistory = async function () {
        const req = await get(`/administration/currentAccount/months`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCashFlowHistory(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCashFlow = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.fileId ? faFile : null;
            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {d.fileId ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.description)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                    {d.fileId ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.description)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);
            if (d.CashFlowCategory != null) {
                d.category = <Badge bg='' className='' style={{ backgroundColor: `${d.CashFlowCategory.color}`, fontSize: 10 }}>{d.CashFlowCategory.name}</Badge>;
            } else {
                d.category = <div></div>
            }

            if (d.CashFlowMethod != null) {
                d.method = <Badge bg='' className='' style={{ backgroundColor: `${d.CashFlowMethod.color}`, fontSize: 10 }}>{d.CashFlowMethod.name}</Badge>;
            } else {
                d.method = <div></div>
            }

            if (d.credit != null) {
                d.balance = `$${pn(d.credit * -1)}`
            } else if (d.debit != null) {
                d.balance = `$${pn(d.debit)}`
            }

            result.push(d)
        }

        setCashFlow(result)
    }

    const createOperation = async function () {
        let debit = null;
        let credit = null;
        let currentAccount = 0;

        if (newOperationIsCredit) {
            credit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
        } else {
            debit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
        }

        let file = null;
        let fileName = null;
        let fileExtension = null;
        let fromCustomerId = null;
        let toUserId = null;

        if (document.getElementById('selectNewOperationToUserId') != null) {
            toUserId = parseInt(document.getElementById('selectNewOperationToUserId').value) != 0 ? parseInt(document.getElementById('selectNewOperationToUserId').value) : null;
        }

        if (document.getElementById('selectNewOperationFromCustomerId') != null) {
            fromCustomerId = parseInt(document.getElementById('selectNewOperationFromCustomerId').value) != 0 ? parseInt(document.getElementById('selectNewOperationFromCustomerId').value) : null;
            currentAccount = debit * -1;
        }

        if (newOperationFile != null) {
            file = await convert2Base64(newOperationFile);
            const aux = newOperationFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
            fileName = newOperationFile.name;
        }

        let body = {
            date: newOperationDate,
            description: document.getElementById('newOperationDescription').value,
            debit: debit,
            credit: credit,
            methodId: parseInt(document.getElementById('selectNewOperationMethodId').value) != 0 ? parseInt(document.getElementById('selectNewOperationMethodId').value) : null,
            categoryId: parseInt(document.getElementById('selectNewOperationCategoryId').value) != 0 ? parseInt(document.getElementById('selectNewOperationCategoryId').value) : null,
            toUserId: toUserId,
            fromCustomerId: fromCustomerId,
            file: file,
            extension: fileExtension,
            userId: getUserId()
        }

        const req = await post(`/administration/cashFlow`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPendingCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/pending/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPendingCurrentAccount(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processPendingCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.avatar = `${params().dataUri}${d.User.avatar}`;
            d.userName = `${d.User.name} ${d.User.surname}`;
            d.icon = d.File ? faFile : null;
            d.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPendingCurrentAccountAsExecuted(d.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como rendida</Dropdown.Item>
                        {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                        {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            result.push(d)
        }

        setPendingCurrentAccount(result)
    }



    const setPendingCurrentAccountAsExecuted = async function (id) {
        const body = {
            id: id
        }

        const req = await put(`/administration/currentAccount/executed`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getPendingCurrentAccount();
            getCashFlow();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            if (res.name.includes(".pdf")) {
                setActualViewFile(res.file)
                handleShowViewFileModal()
            } else {
                downloadFile(fileId, name)
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async function () {
        const body = {
            operationId: deleteOperationId,
        }
        const req = await del(`/administration/currentAccount/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getBills = async function () {
        const req = await get(`/administration/bill/last10/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {


            if (d.date) {
                d.day = (moment(d.date).format('DD/MM/YYYY')).toString().toUpperCase().replace('.', '')
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.fileId) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {d.fileId ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }

    const prepareBillResume = function () {
        let products = [];
        let amount = 0;

        for (let i = 1; i <= 4; i++) {
            if (document.getElementById(`newBillServiceName${i}`).value != "") {
                products.push({
                    name: document.getElementById(`newBillServiceName${i}`).value,
                    qty: parseInt(document.getElementById(`newBillServiceQty${i}`).value),
                    unitary: parseFloat(document.getElementById(`newBillServiceUnitary${i}`).value),
                    amount: parseFloat(document.getElementById(`newBillServiceAmount${i}`).value)
                })

                amount += parseFloat(document.getElementById(`newBillServiceAmount${i}`).value)
            }
        }

        setNewBillDescription(`${products.length == 1 ? `${products[0].name}` : `${products.length} servicios`}`);
        setNewBillAmount(amount);
        setNewBillProducts(products);
        setNewBillCustomerText(document.getElementById('textCustomer').value);
        handleCloseNewBillModal();
        handleShowNewBillConfirmationModal();
    }

    const createBill = async function () {
        const body = {
            startDate: newBillStartDate,
            endDate: newBillEndDate,
            amount: newBillAmount,
            customerId: selectedCustomer.id,
            description: newBillDescription,
            products: newBillProducts
        }
        const req = await post(`/administration/bill/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkBill = () => {
        const a = parseFloat(document.getElementById('newBillAmount').value.replaceAll(',', '.'));
        const d = document.getElementById('newBillService').value;

        if (isNaN(a)) {
            showError("El monto es inválido")
            return false;
        } else if (d.length == 0) {
            showError("No se ha ingresado un artículo")
            return false;
        } else if (selectedCustomer == null) {
            showError("No se ha seleccionado un cliente")
            return false;
        } else if (selectedCustomer.legalIdType == null && a > 19999) {
            showError("El cliente seleccionado tiene los datos fiscales incompletos. Debido al monto a facturar, debés completarlos para realizar la factura")
            return false;
        }
        return true;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Flujo de caja (últimas 10 operaciones)
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="primary" onClick={handleShowCashFlowHistoryModal}><FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />Ver flujo histórico</Button>

                                <Link to='/administracion/flujoCaja'>
                                    <Button className='ms-1' variant="primary"><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver flujo de caja</Button>
                                </Link>

                                <Button className='ms-1' variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Flujo de caja (últimas 10 operaciones)
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/flujoCaja'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} /></Button>
                                </Link>

                                <Button className='ms-1' variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container>
                            <Row className='mb-3'>
                                {cashFlowIndicators.map((ind, i) => (
                                    <Col md={3} sm={12} className='mt-3'>
                                        <Card className={`statusCard ${ind.color} administration`}>
                                            <Card.Body>
                                                <Card.Title>{ind.name}</Card.Title>
                                                <Card.Text>
                                                    <p className='m-0' >
                                                        <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                                        <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                                    </p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                        {cashFlow.length > 0 ?
                            <Container className='pt-3 pb-2'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={5} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Ingreso
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Egreso
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {cashFlow.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM">{r.date}</Moment>

                                                </Col>
                                                <Col xs={5} className='align-center-vertically-v2'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 11 }}>{r.method} {r.category}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.debit != null ? `$${pn(r.debit)}` : "---"}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.credit != null ? `$${pn(r.credit)}` : "---"}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'><span style={{ fontSize: 10 }}><Moment format="DD/MM">{r.date}</Moment></span> {r.method}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{r.balance}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}

                                <Row className='mt-2 p-4' style={{ height: 500 }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={500}
                                            data={cashFlowGraphicData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="debit" name='Ingresos' stroke="#1A8754" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="credit" name='Egresos' stroke="#DC3545" activeDot={{ r: 8 }} />
                                            <Line type="monotone" dataKey="balance" name='Balance' stroke="#49A5DF" activeDot={{ r: 8 }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Row>
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>

                        }



                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Empleados
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button className='ms-1' variant="success" onClick={handleShowNewEmployeeModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo empleado</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Empleados
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>


                        {employees.length > 0 ?
                            <Container className='pt-3 pb-2'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={9} className='align-center-vertically-v2'>
                                            <div>
                                                Nombre y Apellido
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Sueldo
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {employees.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={9} className='align-center-vertically-v2'>
                                                    {r.surname.toUpperCase()}, {r.name}

                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.EmployeeSalary.Currency.symbol}{pn(r.EmployeeSalary.amount)} {r.EmployeeSalary.Currency.nomenclature}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{r.name}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}

                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron empleados</p>
                                </Row>
                            </Container>

                        }


                    </Card>
                </Row>




                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Facturas (últimas 10) | Último intento: {moment(billLastCheck).format('HH:mm')}
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/facturas'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver facturas</Button>
                                </Link>
                                <Button className='ms-1' variant="success" onClick={handleShowNewBillModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva factura</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Facturas (últimas 10)
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/facturas'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} /></Button>
                                </Link>
                                <Button className='ms-1' variant="success" onClick={handleShowNewBillModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {bills.map((b, i) => (
                                <Row className={b.fileId ? `p-2 mb-2` : `ps-2 pe-2 pt-3 pb-3 mb-2`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                        </div>

                                    </Col>
                                    <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'center' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>{b.day}</p>
                                        </div>
                                    </Col>
                                    <Col xs={4} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>{b.customerName}</p>
                                        </div>
                                    </Col>
                                    <Col xs={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                        </div>
                                    </Col>
                                    <Col xs={1} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'right' }}>
                                            {b.action}
                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </Container>

                        {bills.length > 0 ?
                            <Container className='pt-3'>
                                {bills.map((b, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={10} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                                        <p className='m-0'>{b.Customer ? b.Customer.name : ''}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {b.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron facturas</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>



            </Container>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva operación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <ButtonGroup>
                            <ToggleButton
                                key={1}
                                id={1}
                                type="radio"
                                variant='outline-success'
                                name="radio"
                                value={"Ingreso"}
                                checked={newOperationIsCredit === false}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Ingreso
                            </ToggleButton>

                            <ToggleButton
                                key={2}
                                id={2}
                                type="radio"
                                variant='outline-danger'
                                name="radio"
                                value={"Egreso"}
                                checked={newOperationIsCredit === true}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Egreso
                            </ToggleButton>
                        </ButtonGroup>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newOperationAmount" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newOperationDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="selectNewOperationCategoryId">
                            <option value="0">Seleccionar...</option>
                            {paymentCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Método de pago</Form.Text>
                        <Form.Select className='cover content rows element' id="selectNewOperationMethodId">
                            <option value="0">Seleccionar...</option>
                            {paymentMethods.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {newOperationIsCredit ?
                        <Form.Group className='mt-2'>
                            <Form.Text>Pago a</Form.Text>
                            <Form.Select className='cover content rows element' id="selectNewOperationToUserId">
                                <option value="0">Seleccionar...</option>
                                {employees.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        :
                        <Form.Group className='mt-2'>
                            <Form.Text>Recibo de</Form.Text>
                            <Form.Select className='cover content rows element' id="selectNewOperationFromCustomerId">
                                <option value="0">Seleccionar...</option>
                                {customers.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    }

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewOperationFile(event)} type="file" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createOperation}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchCustomers(event)} />
                    {searchedCustomers.length > 0 ? <div></div> : <span></span>}
                    <ListGroup className='mt-2'>
                        {searchedCustomers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id)}>{<div><p className='m-0' style={{ fontSize: 12 }}>{c.legalId ? `CUIT ${c.legalId} - ` : ""}{c.legalName}</p><p className='m-0'></p>{c.name}</div>}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showNewBillModal} size='lg' onHide={handleCloseNewBillModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text>Cliente</Form.Text>
                    <Row className='mb-2'>
                        <Col xs={10}>
                            <Form.Group>
                                <Form.Control type="text" id="textCustomer" disabled placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faSearch} /></Button>
                        </Col>
                    </Row>

                    <Form.Text className='' style={{ display: 'inline' }}>Fecha del servicio</Form.Text>
                    <Form.Group className='mb-2' style={{ display: 'flex' }}>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillStartDate} onChange={(d) => handleNewBillStartDate(d)} />
                        <DatePicker className='form-control cover content rows element ms-1' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillEndDate} onChange={(d) => handleNewBillEndDate(d)} />
                    </Form.Group>

                    <Form.Text className='' style={{ display: 'inline' }}>Servicios</Form.Text>
                    <Form.Group className='' style={{ display: 'flex' }}>
                        <Form.Control type="text" id="newBillServiceName1" placeholder="Nombre" />
                        <Form.Control type="text" id="newBillServiceQty1" className='ms-1' placeholder="Cantidad" />
                        <Form.Control type="text" id="newBillServiceUnitary1" className='ms-1' placeholder="Unitario" />
                        <Form.Control type="text" id="newBillServiceAmount1" className='ms-1' placeholder="Total" />
                    </Form.Group>
                    <div className='mt-1'></div>
                    <Form.Group className='' style={{ display: 'flex' }}>
                        <Form.Control type="text" id="newBillServiceName2" placeholder="Nombre" />
                        <Form.Control type="text" id="newBillServiceQty2" className='ms-1' placeholder="Cantidad" />
                        <Form.Control type="text" id="newBillServiceUnitary2" className='ms-1' placeholder="Unitario" />
                        <Form.Control type="text" id="newBillServiceAmount2" className='ms-1' placeholder="Total" />
                    </Form.Group>
                    <div className='mt-1'></div>
                    <Form.Group className='' style={{ display: 'flex' }}>
                        <Form.Control type="text" id="newBillServiceName3" placeholder="Nombre" />
                        <Form.Control type="text" id="newBillServiceQty3" className='ms-1' placeholder="Cantidad" />
                        <Form.Control type="text" id="newBillServiceUnitary3" className='ms-1' placeholder="Unitario" />
                        <Form.Control type="text" id="newBillServiceAmount3" className='ms-1' placeholder="Total" />
                    </Form.Group>
                    <div className='mt-1'></div>
                    <Form.Group className='' style={{ display: 'flex' }}>
                        <Form.Control type="text" id="newBillServiceName4" placeholder="Nombre" />
                        <Form.Control type="text" id="newBillServiceQty4" className='ms-1' placeholder="Cantidad" />
                        <Form.Control type="text" id="newBillServiceUnitary4" className='ms-1' placeholder="Unitario" />
                        <Form.Control type="text" id="newBillServiceAmount4" className='ms-1' placeholder="Total" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={prepareBillResume}>Siguiente</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewBillConfirmationModal} onHide={handleCloseNewBillConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro de querer generar una factura con estos datos?</p>
                    <Row className='mb-2'>
                        <Col>
                            <Form.Group>
                                <Form.Text>Cliente</Form.Text>
                                <Form.Control type="text" disabled placeholder="" value={newBillCustomerText} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Text className='' style={{ display: 'inline' }}>Fecha del servicio</Form.Text>
                    <Form.Group className='mb-2' style={{ display: 'flex' }}>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' readOnly dateFormat="dd/MM/yyyy" selected={newBillStartDate} onChange={(d) => handleNewBillStartDate(d)} />
                        <DatePicker className='form-control cover content rows element ms-1' locale="es" id='' readOnly dateFormat="dd/MM/yyyy" selected={newBillEndDate} onChange={(d) => handleNewBillEndDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Resumen</Form.Text>
                        <Form.Control type="text" value={newBillDescription} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" value={`$${pn(newBillAmount)}`} readOnly placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillConfirmationModal}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={createBill}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showCashFlowHistoryModal} size='lg' onHide={handleCloseCashFlowHistoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Flujo de caja histórico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                        <Col xs={3} className='align-center-vertically-v2'>
                            <div>
                                Período
                            </div>
                        </Col>


                        <Col xs={3} className='align-center-vertically-v2' >
                            <div style={{ textAlign: 'center' }}>
                                Ingreso
                            </div>
                        </Col>

                        <Col xs={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'center' }}>
                                Egreso
                            </div>
                        </Col>

                        <Col xs={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'center' }}>
                                Balance
                            </div>
                        </Col>

                    </Row>

                    {cashFlowHistory.map((r, i) => (
                        <div>
                            <div className='d-none d-sm-block'>
                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'>{r.month}</p>
                                        </div>

                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.debit != null ? `$${pn(parseFloat(r.debit).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.credit != null ? `$${pn(parseFloat(r.credit).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.balance != null ? `$${pn(parseFloat(r.balance).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>

                                </Row>
                            </div>



                        </div>

                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCashFlowHistoryModal}>
                        Cerrar
                    </Button>




                </Modal.Footer>
            </Modal>

            <Modal show={showEditSalaryEmployeeModal} onHide={handleCloseEditSalaryEmployeeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar sueldo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text>Nuevo sueldo</Form.Text>
                    <Form.Control type="text" id="textEmployeeSalary" placeholder="" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditSalaryEmployeeModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={editEmployeeSalary}>
                        Modificar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewEmployeeModal} onHide={handleCloseNewEmployeeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo empleado</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className='mt-2'>
                        <Form.Text>Nombre</Form.Text>
                        <Form.Control type="text" id="textEmployeeName" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text className=''>Apellido</Form.Text>
                        <Form.Control type="text" id="textEmployeeSurname" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text className=''>CUIL</Form.Text>
                        <Form.Control type="text" id="textEmployeeTaxId" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text className=''>Fecha de ingreso</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={employeeAdmissionDate} onChange={(d) => handleNewEmployeeAdmissionDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text className=''>Fecha de nacimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={employeeBirthdayDate} onChange={(d) => handleNewEmployeeBirthdayDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text className=''>Sueldo</Form.Text>
                        <Form.Control type="text" id="textEmployeeSalary" placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewEmployeeModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={createEmployee}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPaySalaryEmployeeModal} onHide={handleClosePaySalaryEmployeeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagar sueldo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Método de pago</Form.Text>
                        <Form.Select className='cover content rows element' id="selectEmployeePaymentMethodId">
                            <option value="0">Seleccionar...</option>
                            {paymentMethods.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePaySalaryEmployeeModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={payEmployeeSalary}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}