import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { colors } from '../../../tools/constants';
import { pnint } from '../../../tools/tools';

export default function Business(props) {

    const PAGE_TITLE = "Clientes";
    const PAGE_ICON = faIndustry;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `secdevs - ${PAGE_TITLE}`;
        setupTable();
        getCustomers();
    }, []);

    const handleSearch = async function (event) {
        const body = {
            chain: event.target.value
        };
        const req = await post(`/customers/search`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processCustomers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }


    const getCustomers = async function (status) {
        const req = await get('/customers', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomers = async function (data) {
        let result = [];
        for (let r of data) {
            r.action = <Link to={`/clientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            if (r.debt > 0) {
                r.bal = <Badge bg='danger' className='content-table status'>${pnint(r.debt)}</Badge>;
                r.balMobile = <Badge bg='danger' className=''>${pnint(r.debt)}</Badge>;
            }
            result.push(r);
        }
        setTableRows(result);
        setupTable();
    }

    const setupTable = function () {
        const cols = ['Nombre', 'CUIT / DNI', 'Dirección', 'Localidad', '', ''];
        setTableColumns(cols);
    };

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />


            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textSearch" style={{ width: 250, display: 'inline' }} placeholder="" onChange={(event) => handleSearch(event)} />
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to="/clientes/nuevo">
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo cliente</Button>
                                </Link>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                <Form.Control className='ms-2' type="text" id="textSearch" placeholder="Buscar..." onChange={(event) => handleSearch(event)} />
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to="/clientes/nuevo">
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} /></Button>
                                </Link>
                            </Col>
                        </Row>




                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={9} className='align-center-vertically-v2'>
                                            <div>
                                                Cliente
                                            </div>
                                        </Col>



                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Deuda
                                            </div>
                                        </Col>

                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {tableRows.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 11 }}>{r.legalName} {r.legalId ? ` - CUIT/DNI ${r.legalId}` : ''}</p>
                                                        <p className='m-0'>{r.name}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.bal}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={10} className='align-center-vertically'>
                                                    <div>
                                                        {r.balMobile}
                                                        <p className='m-0'>{r.name}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faIndustry}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron clientes</p>
                                </Row>
                            </Container>

                        }



                    </Card>
                </Row>
            </Container>
        </div>
    )
}