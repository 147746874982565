import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge, ButtonGroup, ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes, faFile, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince, convert2Base64, downloadFile } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';

export default function Bills() {

    const PAGE_TITLE = "Facturas";
    const PAGE_ICON = faFile;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tableRows2, setTableRows2] = React.useState([]);


    const [billLastCheck, setBillLastCheck] = React.useState(new Date());

    const [error, setError] = React.useState(<div></div>);
    const [bills, setBills] = React.useState([]);
    const [currentAccountIndicators, setCurrentAccountIndicators] = React.useState([]);
    const [paymentCategories, setPaymentCategories] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [employeesCurrentAccount, setEmployeesCurrentAccount] = React.useState([]);
    const [pendingCurrentAccount, setPendingCurrentAccount] = React.useState([]);
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };
    const [newOperationIsCredit, setNewOperationIsCredit] = React.useState(false);

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };


    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleNewOperationIsCredit = (event) => {
        setNewOperationIsCredit(event == "Egreso");
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewOperationCategory = (event) => {
        setNewOperationCategory(event.target.value);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale('es', es);

        document.title = `secdevs - ${PAGE_TITLE}`;

        getBills();
    }, []);

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getBills = async function () {
        const req = await get(`/administration/bill/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {
            if (d.date) {
                d.day = (moment(d.date).format('DD/MM/YYYY')).toString().toUpperCase().replace('.', '')
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.fileId) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.completeNumber)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }




    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>

                                <Link to={'/administracion'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Administración
                                </Link>
                                <span className='ms-2'>| Facturas | Último intento: {moment(billLastCheck).format('HH:mm')}</span>
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                <Link to={'/administracion'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Administración
                                </Link>
                                <span className='ms-2'>| Facturas</span>
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {bills.map((b, i) => (
                                <Row className={b.fileId ? `p-2 mb-2` : `ps-2 pe-2 pt-3 pb-3 mb-2`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                        </div>

                                    </Col>
                                    <Col xs={2} className='align-center-vertically middle' style={{ textAlign: 'center' }}>
                                        <div>
                                            <p className='m-0'>{b.day}</p>
                                        </div>
                                    </Col>
                                    <Col xs={4} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>{b.customerName}</p>
                                        </div>
                                    </Col>
                                    <Col xs={2} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                        </div>
                                    </Col>
                                    <Col xs={1} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'right' }}>
                                            {b.action}
                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </Container>

                        {bills.length > 0 ?
                            <Container className='pt-3'>
                                {bills.map((b, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={10} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                                        <p className='m-0'>{b.Customer ? b.Customer.name : ''}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {b.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron facturas</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>

            </Container>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}