import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import Home from "./components/ui/home/Home";
import Customers from "./components/ui/customers/Customers";
import CustomerDetails from "./components/ui/customers/CustomerDetails";
import NewCustomer from "./components/ui/customers/NewCustomer";
import Services from "./components/ui/services/Services";
import NewService from "./components/ui/services/NewService";
import Administration from "./components/ui/administration/Administration";
import CashFlow from "./components/ui/administration/CashFlow";
import Bills from "./components/ui/administration/Bills";
import MyBill from "./components/ui/administration/MyBill";
import Contracts from "./components/ui/contracts/Contracts";
import Settings from "./components/ui/settings/Settings";


import { getUserRole } from "./controllers/user.controller";

export function App() {

    const ProcessRoutes = () => {
        let r = getUserRole();
  

        if (r == "Administrador") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    
                    <Route path="/clientes" element={<Customers />} />
                    <Route path="/clientes/detalles" element={<CustomerDetails />} />
                    <Route path="/clientes/nuevo" element={<NewCustomer />} />
                    <Route path="/servicios" element={<Services />} />
                    <Route path="/servicios/nuevo" element={<NewService />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/administracion/flujoCaja" element={<CashFlow />} />
                    <Route path="/administracion/facturas" element={<Bills />} />
                    <Route path="/contratos" element={<Contracts />} />
                    <Route path="/sistema" element={<Settings />} />
                    <Route path="/mifactura" element={<MyBill />} />
                </Routes>
            )
        } else{
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/mifactura" element={<MyBill />} />
                </Routes>)
        }
    }

    return (
        <div>
            <Router>
                <Row className="m-0">
                <ProcessRoutes />


                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}