export const removeTildes = function (chain) {
    return chain.replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o").replace(/ú/g, "u");
}

export const pn = function (number) {
    try{
        const formattedNumber = new Intl.NumberFormat('es-AR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    
        return formattedNumber;
    }catch(e){
        return "error"
    }
}