const DEV = false;

const DEV_PARAMS = {
    apiUri: "http://localhost:8080/api",
    homePage: "http://localhost:3003/inicio",
    dataUri: 'https://micuenta.secdevs.com.ar/data'
};

const PROD_PARAMS = {
    apiUri: "https://ar-caba-sv2.seconline.net.ar:16651/api",
    homePage: "https://micuenta.secdevs.com.ar/inicio",
    dataUri: 'https://micuenta.secdevs.com.ar/data'
};

const COLORS = {
    green: '#28A745',
    brand_primary: '#EDCD1E',
    brand_secondary: '#EDCD1E'
}

export const params = function () {
    if(DEV){
        return DEV_PARAMS;
    }else{
        return PROD_PARAMS;
    }
}

export const colors = function () {
    return COLORS;
}