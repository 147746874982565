import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faCogs, faEye, faLink, faDownload, faMap, faPen, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import { get, put, post, del } from '../../../controllers/endpoints.controller';
import { getUserToken, logout, getUserId } from '../../../controllers/user.controller';
import { convert2Base64, processDate, processDateCalendar, timeDue } from '../../../tools/tools';
import { params, colors } from '../../../tools/constants';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



export default function Settings() {

    moment.locale('es-mx');
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCogs;
    const [usersCol, setUsersCol] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [notifications, setNotifications] = React.useState([]);
    const [appointmentTypes, setAppointmentTypes] = React.useState([]);

    const [color, setColor] = React.useState("");
    const [showNewActionCategoryModal, setShowNewActionCategoryModal] = React.useState(false);
    const handleCloseNewActionCategoryModal = () => { setShowNewActionCategoryModal(false); };
    const handleShowNewActionCategoryModal = () => {
        setShowNewActionCategoryModal(true);
    };

    const [paymentMethods, setPaymentMethods] = React.useState([]);

    const [showNewPaymentMethodModal, setShowNewPaymentMethodModal] = React.useState(false);
    const handleCloseNewPaymentMethodModal = () => { setShowNewPaymentMethodModal(false); };
    const handleShowNewPaymentMethodModal = () => {
        setShowNewPaymentMethodModal(true);
    };

    const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = React.useState(false);
    const handleCloseDeletePaymentMethodModal = () => { setShowDeletePaymentMethodModal(false); };
    const handleShowDeletePaymentMethodModal = (id, name) => {
        setCategoryId(id);
        setCategoryName(name);
        setShowDeletePaymentMethodModal(true);
    };




    const [administratorCategories, setAdministratorCategories] = React.useState([]);
    const [showNewAdministrationCategoryModal, setShowNewAdministrationCategoryModal] = React.useState(false);
    const handleCloseNewAdministrationCategoryModal = () => { setShowNewAdministrationCategoryModal(false); };
    const handleShowNewAdministrationCategoryModal = () => {
        setShowNewAdministrationCategoryModal(true);
    };

    const [categoryId, setCategoryId] = React.useState(null);
    const [categoryName, setCategoryName] = React.useState(null);
    const [showDeleteAdministrationCategoryModal, setShowDeleteAdministrationCategoryModal] = React.useState(false);
    const handleCloseDeleteAdministrationCategoryModal = () => { setShowDeleteAdministrationCategoryModal(false); };
    const handleShowDeleteAdministrationCategoryModal = (id, name) => {
        setCategoryId(id);
        setCategoryName(name);
        setShowDeleteAdministrationCategoryModal(true);
    };

    const [newAppointmentTypeFile, setNewAppointmentTypeFile] = React.useState(null);
    const handleAppointmentTypeFile = async (event) => {
        const file = event.target.files[0]
        setNewAppointmentTypeFile(file)
    }

    const [showNewAppointmentTypeModal, setShowNewAppointmentTypeModal] = React.useState(false);
    const handleCloseNewAppointmentTypeModal = () => { setShowNewAppointmentTypeModal(false); };
    const handleShowNewAppointmentTypeModal = () => {
        setShowNewAppointmentTypeModal(true);
    };


    const [showDeleteAppointmentTypeModal, setShowDeleteAppointmentTypeModal] = React.useState(false);
    const [deleteAppointmentTypeId, setDeleteAppointmentTypeId] = React.useState(0);
    const handleCloseDeleteAppointmentTypeModal = () => { setShowDeleteAppointmentTypeModal(false); };
    const handleShowDeleteAppointmentTypeModal = (id) => {
        setDeleteAppointmentTypeId(id)
        setShowDeleteAppointmentTypeModal(true);
    };

    const [cities, setCities] = React.useState([]);

    const [editWorkZones, setEditWorkZones] = React.useState(false);

    const handleEditWorkZones = () => {
        setEditWorkZones(!editWorkZones)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `secdevs - ${PAGE_TITLE}`;

        getUsers();
        getAdministrationCategories();
        getPaymentMethods();
        getNotifications();
    }, []);

    const handleColor = (event) => {
        setColor(event.target.value);
    };


    const getUsers = async function () {
        const req = await get('/sso/users', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setUsersCol(['', 'Nombre y apellido', 'Correo electrónico', 'Rol', 'Estado', ''])
            processUsers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getNotifications = async function () {
        const req = await get('/notifications/last10', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setNotifications(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCities = (data) => {
        setCities(data)
    }

    const processUsers = (data) => {
        let result = [];

        for (let u of data) {
            u.status = u.enabled ? <Badge bg='success' className='content-table status'>Habilitado</Badge> : <Badge bg='danger' className='content-table status'>Bloqueado</Badge>
            if (u.id == getUserId()) {
                u.actions = <Link to={`/configuracion/usuarios/detalles/`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            } else {
                u.actions = <Link to={`/sistema/usuarios/detalles/?id=${u.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            }

            result.push(u);
        }

        setUsers(result)
    }

    const getActionCategories = async function () {
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActionCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createActionCategory = async function () {
        const data = {
            name: document.getElementById('textActionCategoryName').value,
            color: color
        }
        const req = await post(`/proceedings/actions/categories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getActionCategories();
            handleCloseNewActionCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAppointmentTypes = async function () {
        const req = await get(`/appointments/types`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setAppointmentTypes(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createAppointmentType = async function () {
        let file = null;
        let fileExtension = null;

        if (newAppointmentTypeFile != null) {
            file = await convert2Base64(newAppointmentTypeFile);
            const aux = newAppointmentTypeFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
        }

        const body = {
            name: document.getElementById('textAppointmentTypeName').value,
            icon: file,
            extension: fileExtension
        }

        const req = await post(`/appointments/types`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getAppointmentTypes();
            handleCloseNewAppointmentTypeModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteAppointmentType = async function () {
        const body = {
            id: deleteAppointmentTypeId,
        }
        const req = await del(`/appointment/types`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getAppointmentTypes();
            handleCloseDeleteAppointmentTypeModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPaymentMethods = async function () {
        const req = await get(`/administration/cashFlow/methods`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setPaymentMethods(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAdministrationCategories = async function () {
        const req = await get(`/administration/cashFlow/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setAdministratorCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteAdministrationCategory = async function () {
        const data = {
            id: categoryId
        }
        const req = await del(`/administration/currentAccount/categories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getAdministrationCategories();
            handleCloseDeleteAdministrationCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createAdministrationCategory = async function () {
        const data = {
            name: document.getElementById('textAdministrationCategoryName').value,
            color: color
        }
        const req = await post(`/administration/currentAccount/categories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getAdministrationCategories();
            handleCloseNewAdministrationCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deletePaymentMethod = async function () {
        const data = {
            id: categoryId
        }
        const req = await del(`/administration/currentAccount/methods`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getPaymentMethods();
            handleCloseDeletePaymentMethodModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createPaymentMethod = async function () {
        const data = {
            name: document.getElementById('textAdministrationCategoryName').value,
            color: color
        }
        const req = await post(`/administration/currentAccount/methods`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getPaymentMethods();
            handleCloseNewPaymentMethodModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const updateWorkZones = async function () {
        let wz = [];

        for (let c of cities) {
            wz.push({
                id: c.id,
                monday: document.getElementById(`checkCityMonday_${c.id}`).checked,
                tuesday: document.getElementById(`checkCityTuesday_${c.id}`).checked,
                wednesday: document.getElementById(`checkCityWednesday_${c.id}`).checked,
                thursday: document.getElementById(`checkCityThursday_${c.id}`).checked,
                friday: document.getElementById(`checkCityFriday_${c.id}`).checked,
                saturday: document.getElementById(`checkCitySaturday_${c.id}`).checked,
                sunday: document.getElementById(`checkCitySunday_${c.id}`).checked,
            })
        }

        const body = {
            cities: wz
        }

        const req = await put(`/cities`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const forceSendNotification = async function (notificationId) {
        const data = {
            notificationId: notificationId
        }
        const req = await post(`/notifications/forceSend`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getNotifications();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Usuarios</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Link to="/sistema/usuarios/nuevo">
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo usuario</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3 w-100'>
                            <thead>
                                <tr>
                                    {usersCol.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle' style={{ width: 70 }}><img className='avatar-10' src={`${params().dataUri}${r.avatar}`} /></td>
                                        <td className='content-table rows middle align-middle'>{r.name} {r.surname}</td>
                                        <td className='content-table rows middle align-middle'>{r.email}</td>
                                        <td className='content-table rows middle align-middle'>{r.Role.name}</td>
                                        <td className='content-table rows middle align-middle'>{r.status}</td>
                                        <td className='content-table rows middle align-middle'>{r.actions}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Notificaciones</span>
                                </td>
                                <td className='content-options rows right'>

                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>

                            {notifications.map((r, i) => (
                                <div>
                                    {!r.toTelegram ?
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={1} className='align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0'>
                                                        {r.toEmail ? <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> : <div></div>}
                                                        {r.toWhatsApp ? <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon> : <div></div>}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{moment(r.createdAt).format('DD/MM HH:mm')}</p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'><Badge bg={r.isSent ? 'success' : 'warning'} className='content-table status'>{r.isSent ? 'Enviado' : 'Pendiente'}</Badge></p>
                                                </div>
                                            </Col>
                                            <Col xs={5} className='align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0'>{r.toEmail ? r.toEmail : r.toWhatsApp}</p>
                                                </div>
                                            </Col>
                                            <Col xs={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{r.isRead ? <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> : <div></div>}</p>
                                                </div>
                                            </Col>
                                            <Col xs={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    <p className='m-0'>{r.isSent ? <div></div> : <Button onClick={() => forceSendNotification(r.id)}><FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></Button>}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        : <div></div>}
                                </div>
                            ))}
                        </p>

                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Categorías de pagos</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewAdministrationCategoryModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva categoría</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>

                            {administratorCategories.map((r, i) => (
                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={11} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'><Badge bg='' className='content-table status me-2' style={{ background: `${r.color}` }}><span className='m-1'> </span></Badge> {r.name}</p>
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>

                                                    <Dropdown.Item onClick={() => handleShowDeleteAdministrationCategoryModal(r.id, r.name)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </p>

                    </Card>
                </Row>



                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Métodos de pago</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewPaymentMethodModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo método</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>

                            {paymentMethods.map((r, i) => (
                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={11} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'><Badge bg='' className='content-table status me-2' style={{ background: `${r.color}` }}><span className='m-1'> </span></Badge> {r.name}</p>
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>

                                                    <Dropdown.Item onClick={() => handleShowDeletePaymentMethodModal(r.id, r.name)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </p>

                    </Card>
                </Row>


            </Container>



            <Modal show={showNewAppointmentTypeModal} onHide={handleCloseNewAppointmentTypeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo tipo de cita</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Form.Control type="text" id="textAppointmentTypeName" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Icono</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg' onChange={(event) => handleAppointmentTypeFile(event)} type="file" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewAppointmentTypeModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createAppointmentType}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAppointmentTypeModal} onHide={handleCloseDeleteAppointmentTypeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el tipo de cita?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAppointmentTypeModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteAppointmentType}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAdministrationCategoryModal} centered onHide={handleCloseDeleteAdministrationCategoryModal}>
                <Modal.Header >
                    <Modal.Title>Eliminar categoría</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar la categoría "{categoryName}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAdministrationCategoryModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteAdministrationCategory}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewAdministrationCategoryModal} onHide={handleCloseNewAdministrationCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva categoría de pagos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textAdministrationCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange={(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewAdministrationCategoryModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createAdministrationCategory}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePaymentMethodModal} centered onHide={handleCloseDeletePaymentMethodModal}>
                <Modal.Header >
                    <Modal.Title>Eliminar método de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar el método de pago "{categoryName}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeletePaymentMethodModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deletePaymentMethod}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewPaymentMethodModal} onHide={handleCloseNewPaymentMethodModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo método de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textAdministrationCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange={(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPaymentMethodModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createPaymentMethod}>Confirmar</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}