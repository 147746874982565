import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry, faHandshake, faRefresh, faPen, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { colors } from '../../../tools/constants';
import { pnint } from '../../../tools/tools';
import moment from 'moment';

export default function Contracts() {

    const PAGE_TITLE = "Contratos";
    const PAGE_ICON = faHandshake;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [contractedServices, setContractedServices] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `secdevs - ${PAGE_TITLE}`;
        getContractedServices();
    }, []);

    const getContractedServices = async function () {
        const req = await get(`/services/contract`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setContractedServices(res)
            //processContractedServices(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processContractedServices = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                    </Dropdown.Menu>
                </Dropdown>
            )

            //<Dropdown.Item onClick={() => handleShowNewContractRenewalModal(e.id, e.amount)}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 10, marginLeft: 2 }} />Renovar</Dropdown.Item>
            //<Dropdown.Item onClick={() => handleShowEditContractModal(e)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Editar</Dropdown.Item>

            result.push(e)
        }

        setContractedServices(result)
    }





    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />


            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>

                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                             
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>

                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to="/servicios/nuevo">
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} /></Button>
                                </Link>
                            </Col>
                        </Row>



                        <Container className='mt-3 mb-3'>
                            <div className='d-none d-sm-block'>
                                <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                    <Col sm={5} className='align-center-vertically-v2'>
                                        <div>
                                            Servicio
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Inico
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Vencimiento
                                        </div>
                                    </Col>

                                    <Col sm={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            Estado
                                        </div>
                                    </Col>



                                    <Col sm={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {contractedServices.length > 0 ?
                                <div>
                                    {contractedServices.map((c, i) => (
                                        <div>
                                        <p className='mt-3 mb-1'>{c.customerName}</p>
                                        {c.contracts.map((r, j) => (
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col className='align-center-vertically-v2' xs={5}>
                                                    <p className='m-0' style={{ fontSize: 11 }}><Badge bg={r.automaticRenewal ? "success" : "danger"} className=''>Renovación automática</Badge><Badge bg={r.paymentInAdvance ? "success" : "danger"} className='ms-1'>{r.paymentInAdvance ? "Pago adelantado" : "Pago vencido"}</Badge><Badge bg={r.billAtRenewal ? "success" : "danger"} className='ms-1'>{r.billAtRenewal ? "Facturación auto." : "Sin facturación auto."}</Badge></p>
                                                    <p className='m-0'>{r.Service.name}</p>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {moment(r.startDate).format("DD/MM/YYYY")}
                                                    </div>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {moment(r.endDate).format("DD/MM/YYYY")}
                                                    </div>
                                                </Col>

                                                <Col className='align-center-vertically-v2' xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {moment().isBefore(moment(r.endDate).add(1, 'day')) ? <Badge bg='success' className='content-table status'>Activo</Badge> : <Badge bg='danger' className='content-table status'>Vencido</Badge>}
                                                    </div>
                                                </Col>

                                                <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                    {r.action}
                                                </Col>

                                            </Row>
                                        ))}
                                            
                                        </div>

                                    ))}
                                </div>
                                :
                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faHandshake}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay servicios contratados</p>
                                </Row>
                            }
                        </Container>


                    </Card>
                </Row>
            </Container>
        </div>
    )
}