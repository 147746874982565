import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge, ButtonGroup, ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes, faFile, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout, isLoggedIn } from '../../../controllers/user.controller';
import { timeSince, convert2Base64, downloadFile } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';

export default function Bills() {

    const PAGE_TITLE = "Mi factura";
    const PAGE_ICON = faFile;

    const [fileLoaded, setFileLoaded] = React.useState(false);
    const [bill, setBill] = React.useState(null);
    const [customerName, setCustomerName] = React.useState("");
    const [billNumber, setBillNumber] = React.useState("");

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale('es', es);

        document.title = `secdevs - ${PAGE_TITLE}`;

        getBill();
    }, []);

    const getBill = async function () {
        const req = await get(`/administration/bill/byAccessKey/${getParamId()}`);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setBill(res.file.file)
            setCustomerName(res.bill.customerName)
            setBillNumber(res.bill.completeNumber)
            setFileLoaded(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const downloadFile = async () => {
        const linkSource = `data:application/pdf;base64,${bill}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = billNumber;
        downloadLink.click();
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{customerName}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                            <p className='m-0' style={{ color: '#A0A0A0', fontSize: 20, fontWeight: 300 }}>{customerName}</p>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>

                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={downloadFile}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8 }} />Descargar</Button>
                            </Col>

                            <Col xs={1} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
          
                            </Col>
                            <Col xs={11} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                            <Button variant="success" onClick={downloadFile}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8 }} />Descargar</Button>
                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {fileLoaded ?
                                <PDFViewer document={{
                                    base64: bill,
                                }} hideNavbar={true}/>
                                : ''}
                        </Container>

                        <Container className='pt-3 d-block d-sm-none'>
                            {fileLoaded ?
                                <PDFViewer document={{
                                    base64: bill,
                                }} scale={0.5} hideNavbar={true}/>
                                : ''}
                        </Container>


                    </Card>
                </Row>

            </Container>



        </div>
    )
}